
<template>
  <div class="pres_total_cia" v-if="pdat">
    <div class="cab">TOTAL COMPAÑIA</div>
    <v-sheet v-bind="$cfg.styles.marco">
      <div style="display:flex">
        <v-input_n
          v-bind="$input"
          v-model="diferencia"
          label="% Dif"
          simbolo="%"
          readonly
        >
        </v-input_n>
        <v-input_n
          v-bind="$input"
          v-model="pdat.imp_ant.value"
          label="Año anterior"
          readonly
        >
        </v-input_n>

        <v-input_n
          label="Total"
          v-model="pdat.imp_cia.value"
          v-bind="$input"
          readonly
        ></v-input_n>
      </div>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    pdat: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  },
  computed: {
    diferencia() {
      if (Number(this.pdat.imp_cia.value)===0 || Number(this.pdat.imp_ant.value)===0) return 0;
      return  (
        (100 * (Number(this.pdat.imp_cia.value) - Number(this.pdat.imp_ant.value))) /
        Number(this.pdat.imp_ant.value)
      );
    }
  }
};
</script>
